import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { selectFilterByEntity } from 'api/pcvSlice';
import { getOverview } from 'api/report/reportOverviewSlice';
import { Report, Reports } from 'components/Reports';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CommonRoles, Report as ReportModel } from 'models';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAdminReadOnly } from 'utils';

export const ReportList = ({
  entityName,
}: {
  entityName: string | undefined;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const filterByEntity = useAppSelector(selectFilterByEntity);
  const instance = useAppSelector(selectFilterByEntity).instance;
  const selectedEntityName = entityName ?? t('Filter.AnyEntity').toLowerCase();

  const isReadOnly =
    isAdminReadOnly(accessToken) && !accessToken.isInRole(CommonRoles.Reader);

  const handleDownload = (report: ReportModel) => {
    if (isReadOnly || !instance) {
      return;
    }

    setIsDownloading(true);

    dispatch(
      getOverview({
        entityId: filterByEntity.entityId,
        instanceId: instance.id as string,
        fileName: report.fileName,
        name: report.name,
      })
    ).finally(() => setIsDownloading(false));
  };

  return (
    <>
      <Reports>
        <Heading3 style={{ marginBottom: 10 }}>
          {t('Reports.Reports.ByEntity.Title', {
            entityName: selectedEntityName,
          })}
        </Heading3>

        {!instance?.hasData && (
          <span style={{ fontStyle: 'italic' }}>
            {t('Reports.Reports.ByEntity.NoData', {
              entityName: selectedEntityName,
            })}
          </span>
        )}

        {instance?.hasData && (
          <Report
            report={{
              id: '',
              name: t('Reports.Reports.ByEntity.Name', {
                instanceName: instance.name,
              }),
              fileName: t('Reports.Reports.ByEntity.FileName', {
                period: instance.name,
                entity: entityName ?? t('Filter.AnyEntity').toLowerCase(),
                fileExtension: 'xlsx',
              }),
              contentType: '',
              description: t('Reports.Reports.ByEntity.Description', {
                instanceName: instance.name,
                entityName,
              }),
            }}
            isReadOnly={isReadOnly}
            isDownloading={isDownloading}
            handleDownload={handleDownload}
          />
        )}
      </Reports>
    </>
  );
};

ReportList.displayName = 'ReportList';
